import { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { EditorState, basicSetup } from '@codemirror/basic-setup'
import { EditorView } from '@codemirror/view'
import { xml } from '@codemirror/lang-xml'
import { oneDark } from '@codemirror/theme-one-dark'
import SaxonJS from 'saxon-js'
import './App.css'

const defaultXml = '<foo>\n  <bar>hello world</bar>\n</foo>'

const defaultText = localStorage.getItem('xml') || defaultXml
const defaultSelector = localStorage.getItem('selector') || ''

const serializeXml = (node) => {
  return SaxonJS.serialize(node, {
    method: 'xml',
    indent: false,
    'omit-xml-declaration': true,
  })
}

const parseXML = (xml) => {
  return SaxonJS.getResource({
    text: xml,
    type: 'xml',
  })
}

const evaluateXPath = (selector, contextNode, resultForm = 'default') => {
  return SaxonJS.XPath.evaluate(selector, contextNode, {
    resultForm,
  })
}

export default function App() {
  const [selector, setSelector] = useState(defaultSelector)
  const [text, setText] = useState(defaultText)
  const [contextNode, setContextNode] = useState()
  const [result, setResult] = useState()
  const [error, setError] = useState()

  const extensions = useMemo(() => {
    return [
      basicSetup,
      oneDark,
      xml(),
      EditorView.updateListener.of((v) => {
        if (v.docChanged) {
          setText(v.state.doc.toString())
        }
      }),
    ]
  }, [])

  const init = useCallback(
    (doc) => {
      return new EditorView({
        state: EditorState.create({
          doc,
          extensions,
        }),
      })
    },
    [extensions]
  )

  const [view, dispatch] = useReducer(
    (view, { type, payload }) => {
      switch (type) {
        case 'attach':
          payload.node.appendChild(view.dom)
          break

        case 'clear':
          setSelector('')
          setResult('')
          setError(undefined)

          view.setState(
            EditorState.create({
              doc: defaultXml,
              extensions,
            })
          )

          view.focus()

          localStorage.clear()
          break

        default:
          throw new Error(`Unknown action type ${type}`)
      }

      return view
    },
    text,
    init
  )

  useEffect(() => {
    if (contextNode && selector) {
      setError(undefined)
      setResult([])
      try {
        const results = evaluateXPath(selector, contextNode, 'array')
        if (results) {
          // const lines = results.map((item) =>
          //   evaluateXPath('saxon:line-number(.)', item)
          // )
          const lines = results.map((item) => {
            if (typeof item === 'string') {
              return item
            }

            return serializeXml(item)
          })
          setResult(lines)
        }
      } catch (error) {
        // console.error(error)
        setError(error)
      }
    }
  }, [contextNode, selector])

  useEffect(() => {
    return () => {
      view.destroy()
    }
  }, [view])

  const handleContainer = useCallback((node) => {
    if (node) {
      dispatch({
        type: 'attach',
        payload: { node },
      })
    }
  }, [])

  useEffect(() => {
    if (text) {
      setError(undefined)
      setContextNode(undefined)
      parseXML(text)
        .then((contextNode) => {
          setContextNode(contextNode)
        })
        .catch((error) => {
          setError(error)
        })
      localStorage.setItem('xml', text)
    }
  }, [text])

  const handleChange = useCallback((event) => {
    const selector = event.target.value
    setSelector(selector)
    localStorage.setItem('selector', selector)
    if (selector === '') {
      setResult([])
    }
  }, [])

  const handleClear = useCallback((event) => {
    event.preventDefault()
    dispatch({
      type: 'clear',
    })
  }, [])

  return (
    <div className="container">
      <div className="header">
        <input
          className="selector"
          value={selector}
          placeholder="selector"
          onChange={handleChange}
          autoCapitalize="off"
        />
      </div>

      <div className="panel">
        {result && (
          <div className="output">
            {result.length} {result.length === 1 ? 'match' : 'matches'}
          </div>
        )}
        {error ? (
          <pre className="output error">{error.message}</pre>
        ) : (
          <pre className="output">{result && result.join('\n')}</pre>
        )}
      </div>

      <div className="panel" ref={handleContainer} />

      <div className="footer">
        <button className="clear-button" onClick={handleClear}>
          clear local storage
        </button>
      </div>
    </div>
  )
}
